.date_picker_icon:focus {
    outline: none;
}
.date-input-schedule{
    width: 100%;
}
.date-input-schedule>input {
    width: 100%;
     padding: 18.5px 14px;
    font-size: 15px;
    font-weight: 500;
    border-radius: 4px;
    /* border: 1px solid red; */
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%);
    border-image-slice: 1;

    
    position: relative;
        /* font-family: "Araboto"; */
    cursor: pointer;
}

#otp-box-phone, #otp-box-email{
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%);
    border-image-slice: 1;
    border-radius: 4px;
}
