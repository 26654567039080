.shaka-bottom-controls {
  display: flex;
  flex-direction: column-reverse;
}

.title-box {
  position: absolute;
  z-index: 1000;
  top: 2%;
  left: 1%;
  border: none;
  padding: 0.5%;
  color: rgba(255, 255, 255, 1);
  font-family: 'Araboto-Medium', sans-serif;
  font-size: 30px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  cursor: pointer;
}

/* [dir="rtl"] .title-box{
  left:auto;
  right:1%
} */
.title-box.arabic {
  left: auto;
  right: 1%;
}
.title-div {
  display: flex;
  align-items: center;
}

.seekBarAndDuration {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.shaka-seek-bar-container {
  width: auto;
  min-width: 50%;
  max-width: 95%;
  flex-grow: 1;
}

.video-container{
  width: 900px;
  height: 540px;
  max-width: 100%;
  max-height: 100%;
  display: block;
  overflow: hidden;
  margin-top: 120px;
  
}

@media (max-width: 599px){
  .video-container{
   margin-top: 60px;
    
  }
}

@media (min-width: 600px) and (max-width: 899px){
  .video-container{
   margin-top: 78px;
    
  }
}

@media (min-width: 900px) and (max-width: 1199px){
  .video-container{
   margin-top: 100px;
    
  }
}

.image-container{
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;  
}

[data-shaka-player-container] .shaka-controls-button-panel {
  order: initial;
  overflow: initial;
}

/* [dir="rtl"] .shaka-overflow-menu, 
[dir="rtl"] .shaka-settings-menu{
  right: auto !important;
  left: 15px !important;
  direction: ltr;
} */

/* [dir="ltr"] .shaka-overflow-menu, 
[dir="ltr"] .shaka-settings-menu{
  left: auto !important;
  right: 15px !important;
} */

/* [dir="rtl"] .shaka-seek-bar-container{
  direction: ltr;
  transform: rotate(180deg);
} */

.shaka-controls-container .seekBarAndDuration .timeAndDuration{
  opacity: 0;
}

.shaka-controls-container[shown=true] .seekBarAndDuration .timeAndDuration{
  font-size: 18px;
  color: white;
  opacity: 1;
}

@media (max-width: 750px) {
.shaka-controls-container[shown=true] .seekBarAndDuration .timeAndDuration{
  font-size: 14px;  
}
.title-box {
  font-size: 20px;
}

.up-next{
font-size: 16px;
}
.nextPreview{
  width: 170px  !important;
  height: 200px !important;
}
.previewImg{
  width: 170px !important;
  height: 175px !important;
  }
  .play-icon{
    width: 40px !important;
    height: 40px !important;
  }
  .episode-title{
    font-size: 14px !important;
    padding: 4px !important;
    width: calc(100% - 8px);
  }
}

.nextPreview{
  position: absolute;
  bottom: 7%;
  right: 5%;
  max-width: 50%;
  max-height: 50%;
  width: 210px;
  z-index: 8888888888888;
  box-shadow: 1px 1px 8px 3px #50333300;
  height: 270px;

}

/* [dir="rtl"] .nextPreview{
  right: auto !important;
  left: 5%;
} */

.previewImg{
  width: 210px;
  height: 230px;
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
}

.episode-title{
  position: absolute;
  bottom: 0px;
  font-size: 17px;
  background-color:#1A1B22;
  color: white;
  padding:8px;
  line-height: 1.5;
  width: calc(100% - 16px)
}

.up-next{
  position: absolute;
  top: 0px;
  font-size: 20px;
  background-color: #1A1B22;
  color: white;
  padding:10px;
}

.play-icon{
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%,-50%);
  width: 50px !important;
  height: 50px !important;
  color: white;
}

.cancel-btn{
  margin-top: 10px !important;
  color: white !important;
  background-color: #1A1B22 !important;
  font-size: 18px;
}