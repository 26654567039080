.flexEnd {
    display: flex;
    justify-content: flex-end;
    margin-right: 0px;
}

.flexCenter {
    display: flex !important;
    align-items: center !important;
}

.contentStart {
    justify-content: flex-start;
}

.contentSpaceBetween {
    justify-content: space-between;
}


.borderBottom {
    border-bottom: 1px solid #6869AC;
}

.commentCard {
    margin: 10px;
    display: flex;
    flex-direction: column;
    background-color: rgba(33, 35, 48, 1);
    border-radius: 10px;
    padding: 10px;
}

.commentAvatar {
    border-radius: 50% !important;
    width: 30px !important;
    height: 30px !important;
}

.commentText {
    font-size: 14px !important;
    font-family: 'Araboto-Normal', sans-serif !important;
    font-weight: 400 !important;
    text-align: left !important;
    overflow-wrap: break-word !important;
}

.textRight {
    text-align: right !important;
}

.commentContainer {
    overflow-y: scroll;
    max-height: 265px;
}

.commentInputBox {
    margin: auto 10px 10px 10px;
}

.socialAvatar {
    display: flex;
    justify-content: space-around;
}

.pl5 {
    padding-left: 5px;
}

.copyImage {
    width: 30px;
    height: 30px
}

.p10 {
    padding: 10px;
}


.mlr10 {
    margin: 0 10px 0 10px;
}