.cnpBillingCheckoutWrapper {
  position: relative;
}

.cnpBillingCheckoutHeader {
  width: 100%;
  border-bottom: 1px solid #c0c0c0;
  margin-bottom: 10px;
}

.cnpBillingCheckoutLeft {
  width: 240px;
  margin-left: 5px;
  margin-bottom: 10px;
  border: 1px solid #c0c0c0;
  display: inline-block;
  vertical-align: top;
  padding: 10px;
}

.cnpBillingCheckoutRight {
  width: 50%;
  margin-left: 5px;
  border: 1px solid #c0c0c0;
  display: inline-block;
  vertical-align: top;
  padding: 10px;
}

.cnpBillingCheckoutOrange {
  font-size: 110%;
  color: rgb(255, 60, 22);
  font-weight: bold;
}

div.wpwl-wrapper,
div.wpwl-label,
div.wpwl-sup-wrapper {
  width: 100%;
}

div.wpwl-group-expiry,
div.wpwl-group-brand {
  width: 50%;
  float: left;
}

div.wpwl-group-cvv {
  width: 48%;
  float: left;
  margin-left: 2%;
}

div.wpwl-group-cardHolder,
div.wpwl-sup-wrapper-street1,
div.wpwl-group-expiry {
  clear: both;
}

div.wpwl-sup-wrapper-street1 {
  padding-top: 1px;
}

div.wpwl-wrapper-brand {
  width: auto;
}

div.wpwl-sup-wrapper-state,
div.wpwl-sup-wrapper-city {
  width: 32%;
  float: left;
  margin-right: 2%;
}

div.wpwl-sup-wrapper-postcode {
  width: 32%;
  float: left;
}

div.wpwl-sup-wrapper-country {
  width: 66%;
}

div.wpwl-wrapper-brand,
div.wpwl-label-brand,
div.wpwl-brand {
  display: none;
}

div.wpwl-group-cardNumber {
  width: 50%;
  float: left;
  font-size: 20px;
}

@media only screen and (max-width: 479px) {
  div.wpwl-group-cardNumber {
    float: none;
    width: 100%;
  }
}

div.wpwl-group-brand {
  width: 48%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

@media only screen and (max-width: 479px) {
  div.wpwl-group-brand {
    margin: 5px 0 15px;
    width: 100%
  }
}

div.wpwl-brand-card {
  width: 65px;
}

div.wpwl-brand-custom {
  margin: 0px 5px;
  background-image: url("https://test.oppwa.com/v1/paymentWidgets/img/brand.png");
}

.wpwl-container .wpwl-target{
  height: 100% !important;
 background: #ffffffbd;
 border-width: 3px;
              border-style: solid;
              border-image: linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%);
              border-image-slice: 1;
min-height: 100px;
box-shadow: 0px 0px 30px rgba(241, 239, 239, 0.4);
max-height: 450px;
}
