/* @import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css"; */

/* .slider {
  margin : auto;
} */
/* .podcast_slider .slick-slide {
  margin: 0px 6px 0px -100px !important;
} */

/* .podcast_slider .swiper-container {
  height: 100% !important;
}

@media only screen and (max-width: 480px) {
  .podcast_slider .swiper-container {
    height: 82% !important;
  }
} */
/*
@media only screen and (min-width: 600px) {
  .swiper-container  {
    height: 76% !important;
    background-color: blueviolet;
  }
}
@media only screen and (min-width: 960px) {
  .swiper-container  {
    height: 106% !important;slider
  }
}
 */

 /* .swiper-container{
    height: 100%;
   }
  
  .podcast_slider .swiper-pagination {
    position: absolute;
    bottom: 0px !important;
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    width: 200px !important;
  }
  
  @media only screen and (max-width: 480px) {
    .podcast_slider .swiper-pagination {
      bottom: 0px !important;
    }
  }
  
  .podcast_slider .swiper-pagination-bullet {
    background-color: white !important;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    opacity: 0.8;
  }
  .mySwiper_light .swiper-pagination-bullet{
    background-color: rgb(0, 0, 0) !important;
    opacity: 0.8;
  }
  
  .podcast_slider .swiper-pagination-bullet-active {
    background: rgba(234, 67, 93, 1) !important;
    width: 2rem !important;
    height: 1rem !important;
    border-radius: 5px !important;
    opacity: 1;
  }
  
  .podcast_slider .slider1 {
    margin: auto;
    width: 90%;
    height: 30vh;
  }
  
  .podcast_slider .slide {
    position: relative;
  }
  
  .podcast_slider .slide,
  .slide .slideImage {
    width: 100%;
    height: 50vh !important;
    object-fit: center;
    border-radius: 8px !important;
  }
  
  @media only screen and (min-width: 600px) {
    .podcast_slider .slide,
    .slide .slideImage {
      width: 100%;
      height: 35vh;
    }
  }
  
  @media only screen and (max-width: 960px) {
    .podcast_slider .slide,
    .slide .slideImage {
      width: 100%;
      height: 45vh;
    }
  }
  
  .podcast_slider .slide {
    transform: scale(1);
  }
  
  .podcast_slider .swiper-wrapper {
    margin: 0vh auto !important;
  }
  
  .podcast_slider .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 350px;
    height: 300px;
  }
  
  @media only screen and (max-width: 600px) {
    .podcast_slider .swiper-slide {
      width: 340px;
      height: 280px;
    }
  }
  
  .podcast_slider .swiper-slide-active .buttons {
    display: flex !important;
  } */
  
  /* @media (max-width: 480px) {
    .podcast_slider .swiper-slide-active .buttons {
      position: absolute;
      bottom: -100px !important;
    }
  }
  
  @media (max-width: 380px) {
    .podcast_slider .swiper-slide-active .buttons {
      position: absolute;
      bottom: -50px !important;
    }
  } */
  
  /* .podcast_slider .swiper-slide .buttons {
    display: none;
  } */
  
  /* @media only screen and (max-width: 600px) {
    .podcast_slider .swiper-slide .buttons {
      position: absolute;
      bottom: -30px;
    }
  } */
  
  /* .podcast_slider .slide {
    transform: translate3d(0px, 60px, -140px) rotateY(-38.1143deg) scale(0.7) !important;
  }
  
  .podcast_slider .swiper-slide-duplicate {
    transform: translate3d(0px, 60px, -140px) rotateY(38.1143deg) scale(0.7) !important;
  }
  
  .podcast_slider .swiper-slide-active {
    transform: translate3d(0px, 40px, 0px) rotateX(0deg) rotateY(0deg) scale(1.3) !important;
    border-radius: 30px !important;
    margin-right: 0 !important;
  }
  
  .podcast_slider .swiper-slide-prev {
    transform: translate3d(0px, 30px, 100px) rotateX(0deg) rotateY(38.1143deg)
      scale(0.8) !important;
      margin-right:  0 !important;
  }
  
  @media only screen and (min-width: 600px) {
    .podcast_slider .swiper-slide-prev {
      transform: translate3d(0px, 58px, 100px) rotateX(0deg) rotateY(38.1143deg)
        scale(0.8) !important;
    }
  }
  
  .podcast_slider .swiper-slide-next {
    transform: translate3d(0px, 90px, 100px) rotateX(0deg) rotateY(-38.1143deg)
      scale(0.8) !important;
      margin-right:  20 !important;
  
  }
  
  @media only screen and (min-width: 600px) {
    .podcast_slider .swiper-slide-next {
      transform: translate3d(0px, 58px, 100px) rotateX(0deg) rotateY(-38.1143deg)
        scale(0.8) !important;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .podcast_slider .swiper-slide-active {
      transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) scale(1) !important;
      border-radius: 30px !important;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .podcast_slider .swiper-slide-next {
      transform: translate3d(0px, 30px, 100px) rotateX(0deg) rotateY(0deg)
        scale(0.8) !important;
    }
  } */
  
  
  /* .buttons {
    position: relative !important;
    top: -80px !important;
  } */
  
  /* .swiper-container .swiper-button-next {
    right: 8% !important;
    left: auto !important;
    top: 54% !important;
    background-color: #fff;
    color: black;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  
  .swiper-container .swiper-button-next::after{
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
  }
   */
  /* @media only screen and (max-width: 600px) {
    .swiper-slide .buttons {
      position: absolute;
      bottom: -30px;
    }
  } */
  .chips span{
  background: aliceblue;
  height: 90px;
  }
  .Toastify__toast-container {
    z-index: 99999999 !important;
  }
  