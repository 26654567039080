.episodeContainer {
    display: flex;
    flex-direction: row;
}


.episodeCard {
    opacity: 1 !important;
    background-color: transparent !important;
    border-radius: 0 !important;
    padding: 0 30px !important;
    max-height: 75px !important;
    min-height: 75px !important;
    box-shadow: none !important;
    overflow: visible !important;
    border-left: 1px solid #EA435D;
    cursor: pointer;
}

.episodeCard:first-child{
    padding: 0 30px 0 0 !important;
    border-left: 1px solid transparent;
}

.episodeArabicCard{
    opacity: 1 !important;
    background-color: transparent !important;
    border-radius: 0 !important;
    padding: 0 30px !important;
    max-height: 75px !important;
    min-height: 75px !important;
    box-shadow: none !important;
    overflow: visible !important;
    border-right: 1px solid #EA435D;
    cursor: pointer;
}

.episodeArabicCard:first-child{
    padding: 0 0 0 30px !important;
    border-right: 1px solid transparent;
}

.episodeAvatar {
    max-width: 75px !important;
    max-height: 75px !important;
    min-width: 75px !important;
    min-height: 75px !important;
    border-radius: 8px !important;
    opacity: 1 !important;
    object-fit: fill !important;
}

.playButton {
    border: 1px solid rgba(255, 255, 255, 1);
    opacity: 1;
    background-color: rgba(26, 27, 34, 1);
}

.episodeContent {
    opacity: 1 !important;
    font-family: "Araboto-Bold" !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    font-style: normal !important;
    letter-spacing: 0px !important;
    text-align: left !important;
    padding: 0 !important;
}

.episodeSubTitle {
    opacity: 1;
    color: rgba(177, 182, 205, 1);
    font-family: "Araboto-Normal";
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
}

.episodeSubTitle span {
    border-right: 1px solid;
    padding-right: 5px;
}

.episodeMoreButton {
    margin-left: auto !important;
    align-items: flex-start !important;
    font-size: 15px !important;
    font-family: "Roboto" !important;
    font-weight: 500 !important;
    font-style: normal !important;
    letter-spacing: 0px !important;
    text-align: right !important;
    padding: 10% 0 0 0 !important;
    width:50px;
    text-transform: capitalize;
}

.textDiv {
    max-width: 85% !important;
}

.lineClamp3 {
    display: -webkit-box;
   -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;  
    overflow: hidden;
}

.lineClamp2 {
    display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;  
    overflow: hidden;
}

.lineClamp1 {
    display: -webkit-box;
   -webkit-line-clamp: 1;
   -webkit-box-orient: vertical;  
    overflow: hidden;
}

.iconSize {
    font-size: 2.5rem !important;
}

.iconButton {
    width: 40px !important;
    height: 48px !important;
    color: #FFFFFF !important;
}

.columnRight {
    display: flex !important;
    flex-direction: column !important;
    max-height: 80% !important;
    justify-content: space-between;
}

.buttonsFlex {
    display: flex;
    flex-direction: row;
    padding: 0 !important;
}

.downloadButton {
    margin-top: auto !important;
    padding: 0px 14px 0 14px !important;
}
.continueWatching{
    margin-top: auto !important;
    padding: 0px !important;
}

.pl5{
    padding-left: 5px !important;
}