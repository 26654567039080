.containerImg {
    background-size: cover !important;
    background-position: center center !important;
    position: absolute !important;
    object-fit: contain !important;
    font-weight: 700 !important;
    height: 100% !important;
    width: 100% !important;
    overflow-x: hidden !important;
    padding-left: 0 !important;
}

.boxMain {
    padding: 0% 5% 5% 5% !important;
}

.containerClass {
    height: 100% !important;
    position: absolute !important;
    left: 0 !important;
    width: 100% !important;
    overflow: hidden !important;
    padding: 10px !important;
}

.starLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 6px !important;
}

.starName {
    opacity: 1 !important;
    /* color: rgba(255, 255, 255, 1) !important; */
    font-family: "Roboto" !important;
    font-size: 28px !important;
    font-weight: 100 !important;
    font-style: normal !important;
    letter-spacing: 0px !important;
    text-align: left !important;
    line-height: 48px !important;
    margin: 0 15px !important;
}

.gridContainer {
    margin-top: 2% !important;
}

.cardContainer {
    display: flex;
    flex-direction: row;
}


.cardDetails {
    display: flex;
    flex-direction: column;
    padding: 5px 10px 0 !important;
}

.cardAvatar {
    width: 125px !important;
    height: 110px !important;
    border-radius: 10px !important;
    opacity: 1 !important;
    object-fit: fill !important;
}

.playButton {
    border: 1px solid rgba(255, 255, 255, 1);
    opacity: 1;
    background-color: rgba(26, 27, 34, 1);
}

.cardContent {
    text-align: left;
    padding: 0 !important;
    opacity: 1;
    font-family: "Araboto-Medium";
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0px;
    line-height: 21px;
}

[dir="rtl"] .cardContent, .cardSubTitle, .cardText {
    text-align: right !important;
}
.cardSubTitle {
    text-align: left !important;
    opacity: 1;
    font-family: "Araboto-Normal";
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
}

.cardSubTitle span {
    border-right: 1px solid;
    padding-right: 5px;
}

.cardMoreButton {
    margin-left: auto !important;
    align-items: flex-start !important;
    padding: 5px !important;
}

.cardMoreButton:hover {
    background-color: "transparent" !important;
    cursor: "default" !important;
}

.textDiv {
    margin-top: 10px;
    padding-bottom: 10px;
}

.cardText {
    opacity: 1;
    font-family: "Araboto-Normal" !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    font-style: normal;
    letter-spacing: 0px !important;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    margin-bottom: auto !important;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.iconSize {
    font-size: 2.5rem !important;
}

.iconButton {
    width: 40px !important;
    height: 48px !important;
    /* color: #FFFFFF !important; */
}