@font-face {
  font-family: 'Araboto';
  src: url('./fonts/Araboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Araboto';
  src: url('./fonts/Araboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Araboto';
  src: url('./fonts/Araboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Araboto';
  src: url('./fonts/Araboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Araboto-Normal';
  src: url('./fonts/Araboto-Normal.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Araboto-Normal';
  src: url('./fonts/Araboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

body{
  font-family: "Araboto";
}

.single_line {
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .two_lines {
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .four_lines {
    display: -webkit-box !important;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  [dir="rtl"] .switchDirection{
    display: flex;
  }

  [dir="rtl"] .switchDirection span {
    margin-left: 5px;
  }
  
  [dir="rtl"] .switchDirection span:nth-child(2) {
    order: -1; 
  }