
 .swiper-container{
  height: 100%;
 }

.swiper-pagination {
  position: absolute;
  bottom: 0px !important;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}


.swiper-pagination-bullet {
  background-color: #D9D9D9 !important;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  opacity: 0.8;
}
.mySwiper_light .swiper-pagination-bullet{
  background-color: rgb(0, 0, 0) !important;
  opacity: 0.8;
}

.swiper-pagination-bullet-active {
  background: #D9D9D9 !important;
  width: 16px !important;
  height: 8 !important;
  border-radius: 5px !important;
  opacity: 1;
}

.slider1 {
  margin: auto;
  width: 90%;
  height: 30vh;
}

.slide {
  position: relative;
  
}

.slide,
.slide .slideImage {
  width: 100%;
  height: calc(100% - 25px) !important;
  position: relative;  
  max-height: 800px;
}
.slide{
  background-size: 100% 100%;
  object-fit: cover;
}
@media only screen and (min-width: 600px) {
  .slide,
  .slide .slideImage {
    width: 100%;
    /* height: 35vh; */
  }
}

.react-loading-skeleton{
  --base-color: #262424;
--highlight-color: #1c1616;
--animation-duration: 2s;
}

@media only screen and (max-width: 960px) {
  .slide,
  .slide .slideImage {
    width: 100%;
    /* height: 45vh; */
  }
}

.slide {
  transform: scale(1);
}

.swiper-slide {
  background-position: center;
  background-size: 100% 100%;
  object-fit: cover;
}


.swiper-slide-active .buttons {
  display: flex !important;
}

.swiper-slide .buttons {
  display: none;
}

[dir="rtl"] .swiper-container .swiper-button-prev {
  right: 8% !important;
  left: auto !important;
}

[dir="rtl"] .swiper-container .swiper-button-next {
  left: 8% !important;
  right: auto !important;
}

.swiper-container .swiper-button-prev {
  left: 8% !important;
  right: auto !important;
  top: 50% !important;
  background-color: #fff;
  color: black;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.swiper-container .swiper-button-prev::after{
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
}

.swiper-container .swiper-button-next {
  right: 8% !important;
  left: auto !important ;
  top: 50% !important;
  background-color: #fff;
  color: black;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.swiper-container .swiper-button-next::after{
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
}

.Toastify__toast-container {
  z-index: 99999999 !important;
}
