.movie-carousel {
    position: relative;
    overflow-x: hidden;
}

.slider-container {
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 20px 0 0 0;
    transition: all 300ms;
}

.slider-container::-webkit-scrollbar {
    display: none;
}

.movie-card{
    display: inline-flex;
    position: relative;
    width: 100%;
    transform: scale(1);
    transition: all 300ms;
}

.movie-card:hover{
    transform: scale(1.02)
}
